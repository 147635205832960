import React from 'react';
import SpawnChanceGraph from './SpawnChanceGraph';
import './App.css';

function App() {
  return (
      <div className="App">
        <SpawnChanceGraph />
      </div>
  );
}

export default App;
